import Link from 'next/link';
import Image from '../global/image';
import { event_select_promotion, event_view_promotion } from '@/utils/gtm';
import useInViewPort from '@/hooks/global/useInViewPort';

export default function SingleBanner({
  target,
  index,
  className,
  imgSrc,
  alt,
  banner,
  fetchpriority = 'law',
  position,
  sectionType = null,
}) {
  function fireViewPromotion() {
    setTimeout(() => {
      event_view_promotion(imgSrc, index, position, banner?.id, sectionType);
    }, 500);
  }
  const [ref] = useInViewPort(fireViewPromotion);

  return banner?.url ? (
    <Link
      onClick={() =>
        event_select_promotion(imgSrc, index, position, banner?.id, sectionType)
      }
      href={target}
      ref={ref}
      key={`single-banner-${index}`}
    >
      <Image
        loading='eager'
        src={imgSrc}
        className={className}
        alt={alt}
        fetchpriority={fetchpriority}
      />
    </Link>
  ) : (
    <Image
      loading='eager'
      src={imgSrc}
      className={className}
      alt={alt}
      fetchpriority={fetchpriority}
    />
  );
}
