import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import { imgSourceHandler } from '@/utils/generic';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import useScreenSize from '@/hooks/display/useDisplay';
import SingleBanner from './SingleBanner';
import { handlePromotionQuery } from '@/utils/global';
import Arrow from '../icons/Arrow';
import { useState } from 'react';
import UseLocale from '@/hooks/useLocale';

const Banners = ({ data, sectionType }) => {
  console.log('data in Banners', data);
  const { locale } = UseLocale();
  const { screenWidth, isResponsive } = useScreenSize();
  const [swiper, set_swiper] = useState({});
  const activeMainBanners = data?.main_banners?.value?.filter(
    (item) => item?.active
  );

  const activeMain_slider = data?.main_slider?.value?.filter(
    (item) => item?.active
  );
  const activeSideBanners = data?.side_banners?.value?.filter(
    (item) => item?.active
  );
  const webActiveSideBanners = activeSideBanners?.slice(0, 3);
  const mobActiveSideBanners = activeSideBanners?.slice(0, 4);
  return (
    <div className='grid grid-cols-12 overflow-hidden'>
      <div className='hidden lg:col-span-2 lg:block'></div>
      <div className='col-span-full flex gap-2 max-lg:flex-col max-md:gap-3 max-sm:gap-3.5 lg:col-span-10 lg:items-start'>
        <div className='z-[3] col-span-full grid grid-cols-1 max-sm:max-h-[290px] max-sm:min-h-[290px] lg:flex-1'>
          {data?.main_slider?.active && activeMain_slider?.length > 0 ? (
            <Swiper
              modules={[Autoplay]}
              autoplay={{ delay: 4000, disableOnInteraction: false }}
              key={locale}
              pagination={{ clickable: true }}
              dir={locale === 'en' ? 'ltr' : 'rtl'}
              // className="w-full h-full banner-swiper"
              loop={activeMain_slider?.length > 1}
              className='h-[535px] w-full max-sm:max-h-[270px] max-sm:min-h-[270px] '
            >
              {activeMain_slider?.map((banner, i) => (
                <SwiperSlide key={`active-main-slider-${i}`}>
                  <SingleBanner
                    position={+data?.position}
                    sectionType={sectionType}
                    target={
                      handleLinkByType(banner?.url, banner?.type) +
                      handlePromotionQuery({ banner: banner, locale, i })
                    }
                    index={0}
                    key={1}
                    imgSrc={imgSourceHandler(
                      banner?.[
                        `${isResponsive ? 'mobile-' : ''}img-${locale.includes('ar') ? 'ar' : 'en'}`
                      ]
                    )}
                    alt={banner?.[`title-${locale}`]}
                    banner={banner}
                    className='w-full object-contain'
                    fetchpriority='high'
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}

          <div className='relative z-10 -mt-[50px] flex w-full justify-center gap-1 max-sm:-mt-16 max-sm:max-h-[270px] max-sm:min-h-[270px]'>
            {activeMainBanners.length > 4 && (
              <>
                <button
                  onClick={() => swiper?.slidePrev()}
                  className='absolute -start-2 top-1/2 z-50 grid size-8 -translate-y-1/2 place-items-center rounded-full border bg-white shadow-xl max-sm:hidden'
                >
                  <Arrow
                    className={locale === 'en' ? 'rotate-90' : '-rotate-90'}
                  />
                </button>
                <button
                  onClick={() => swiper?.slideNext()}
                  className='absolute -end-2 top-1/2 z-50 grid size-8 -translate-y-1/2 place-items-center rounded-full border bg-white shadow-xl max-sm:hidden'
                >
                  <Arrow
                    className={locale === 'en' ? '-rotate-90' : 'rotate-90'}
                  />
                </button>
              </>
            )}
            {activeMainBanners?.length ? (
              <Swiper
                modules={[Autoplay]}
                onSwiper={set_swiper}
                autoplay={{ delay: 4000, disableOnInteraction: false }}
                key={locale}
                pagination={{ clickable: true }}
                dir={locale === 'en' ? 'ltr' : 'rtl'}
                slidesPerView='4'
                spaceBetween='12'
                loop={activeMain_slider?.length > 4}
                className='flex h-[200px] w-full justify-center'
              >
                {data?.main_banners?.active &&
                  activeMainBanners?.map((banner, i) => (
                    <SwiperSlide key={i}>
                      <SingleBanner
                        position={+data?.main_banners?.position}
                        target={
                          handleLinkByType(banner?.url, banner?.type) +
                          handlePromotionQuery({ banner: banner, locale, i })
                        }
                        index={0}
                        key={1}
                        imgSrc={imgSourceHandler(
                          banner?.[
                            `${isResponsive ? 'mobile-' : ''}img-${locale.includes('ar') ? 'ar' : 'en'}`
                          ]
                        )}
                        alt={banner?.[`title-${locale}`]}
                        banner={banner}
                        className='h-[200px] object-contain max-sm:max-h-[110px] max-sm:min-h-[110px]'
                        fetchpriority='high'
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            ) : null}
          </div>
        </div>
        {webActiveSideBanners?.length || mobActiveSideBanners?.length
          ? data?.side_banners?.active && (
              <div className='z-[2] col-span-full grid grid-cols-2 flex-col justify-between border-none max-lg:gap-1 max-sm:mt-2 lg:flex lg:gap-1'>
                {(screenWidth > 1020
                  ? webActiveSideBanners
                  : mobActiveSideBanners
                )?.map((banner, i) => (
                  <SingleBanner
                    position={+data?.side_banners?.position}
                    target={
                      handleLinkByType(banner?.url, banner?.type) +
                      handlePromotionQuery({ banner: banner, locale, i })
                    }
                    index={0}
                    key={1}
                    imgSrc={imgSourceHandler(
                      banner?.[
                        `${screenWidth < 500 ? 'mobile-' : ''}img-${locale}`
                      ]
                    )}
                    alt={banner?.[`title-${locale}`]}
                    banner={banner}
                    className='h-[225px] w-full object-cover max-sm:max-h-[110px] max-sm:min-h-[110px]'
                    fetchPriority='high'
                  />
                ))}
              </div>
            )
          : null}
      </div>
    </div>
  );
};

export default Banners;
