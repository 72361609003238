import useGetAwsPersonalizeProducts from '@/hooks/useGetAwsPersonalizeProducts';
import {
  BEST_SELLER,
  MOST_VIEWED,
  MY_RECOMMENDATIONS,
} from '@/services/global/aws-personalize';
import React from 'react';
import ProductsSwiperSection from '../product/ProductsSwiperSection';
import { useRouter } from 'next/router';

export default function HomeAwsProducts({ data, t }) {
  const { locale: language } = useRouter();

  const awsKey =
    data?.type === 'recommended'
      ? MY_RECOMMENDATIONS
      : data?.type === 'bestselling'
        ? BEST_SELLER
        : data?.type === 'mostviewed'
          ? MOST_VIEWED
          : null;

  const { products, ratings } = useGetAwsPersonalizeProducts(awsKey);

  return products?.length > 0 ? (
    <ProductsSwiperSection
      {...{
        t,
        language,
        products: products,
        title: data?.[`title-${language}`],
        backgroundColor: data?.['bg-color'],
      }}
      ratings={ratings}
      fromHomePage
    />
  ) : null;
}
