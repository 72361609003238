import Banners from '@/components/home/Banners'; // eager

import { getProductsByCategoryId } from '@/services/category';
import LazyLoading from '@/components/LazyLoading';
import dynamic from 'next/dynamic';
import HomeAwsProducts from '@/components/home/HomeAwsProducts';

const Products = dynamic(() => import('../../components/home/Products'), {
  loading: () => <LazyLoading parentClassName={'min-h-[420px]'} />,
});
const CategoriesSlider = dynamic(
  () => import('../../components/home/CategoriesSlider'),
  {
    loading: () => (
      <LazyLoading parentClassName={'min-h-[300px] sm:min-h-[600px]'} />
    ),
  }
);
const Brands = dynamic(() => import('../../components/home/Brands'), {
  loading: () => <LazyLoading parentClassName={'min-h-[200px]'} />,
});
const StaticBanners = dynamic(
  () => import('../../components/home/StaticBanners'),
  {
    loading: () => (
      <LazyLoading parentClassName={'min-h-[300px] sm:min-h-[500px]'} />
    ),
  }
);
const StaticBannerSwiper = dynamic(
  () => import('../../components/global/swiper/StaticBannerSwiper'),
  {
    loading: () => (
      <LazyLoading parentClassName={'min-h-[160px] sm:min-h-[300px]'} />
    ),
  }
);
const OurSwiper = dynamic(
  () => import('../../components/global/swiper/OurSwiper'),
  {
    loading: () => (
      <LazyLoading parentClassName={'min-h-[383px] sm:min-h-[450px]'} />
    ),
  }
);

export const getTabsData = async (handshake, tabs) => {
  if ('value' in tabs) {
    const firstTab = await getProductsByCategoryId(
      handshake,
      tabs?.value?.at(0)?.id,
      '',
      12,
      0,
      false
    );
    const tabsProducts = tabs.value.map((tab, idx) =>
      idx === 0
        ? {
            ...tab,
            products: firstTab.data?.data?.products || [],
          }
        : { ...tab }
    );
    return { ...tabs, value: tabsProducts };
  }
  return tabs;
};

export const getTabData = async (handshake, value) => {
  const firstTab = await getProductsByCategoryId(
    handshake,
    value?.id,
    '',
    12,
    0,
    false
  );
  return { ...value, products: firstTab.data?.data?.products || [] };
};

export const getCategorySectionData = async (handshake, categorySection) => {
  const productsInCategoriesResponse = await Promise.all(
    categorySection?.value?.map((cat) =>
      getProductsByCategoryId(handshake, cat?.id, '', 6, 0, false)
    )
  );

  const categorySectionWithProducts = categorySection?.value?.map(
    (cat, idx) => ({
      ...cat,
      products: productsInCategoriesResponse?.[idx]?.data?.data?.products || [],
    })
  );
  return { ...categorySection, value: categorySectionWithProducts };
};

export const renderHomeSection = (sectionType, CurrentData, t, isMobile) => {
  switch (sectionType) {
    case 'first-section':
      return <Banners sectionType={sectionType} data={CurrentData} />; //eager
    case 'today-offers':
    case 'products-list':
      return <Products sectionType={sectionType} data={CurrentData} />;
    case 'categories-slider':
      return <CategoriesSlider sectionType={sectionType} data={CurrentData} />;
    case 'brands':
      return <Brands sectionType={sectionType} data={CurrentData} t={t} />;
    case 'banners':
      return <StaticBanners sectionType={sectionType} data={CurrentData} />;
    case 'offers-slider':
      return (
        <StaticBannerSwiper
          sectionType={sectionType}
          data={CurrentData}
          t={t}
        />
      );
    case 'banner-products-list':
      return (
        <OurSwiper
          isMobile={isMobile}
          sectionType={sectionType}
          data={CurrentData}
        />
      );
    case 'aws':
      return <HomeAwsProducts data={CurrentData} t={t} />;
    default:
      return null;
  }
};
