import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

import { fetchHandshake } from '@/services/handshake';
import { getHomeJson } from '@/services/home';
import { renderHomeSection } from '@/utils/home';
import Seo from '@/components/seo';
import { metadata } from '@/constants/metadata';
import { setCookie } from 'cookies-next';
import useScreenSize from '@/hooks/display/useDisplay';
import { homeSchema } from '@/utils/product/schema';
import UseLocale from '@/hooks/useLocale';

const HomePage = ({ home: homejson }) => {
  // useGlobalVariables()
  const { isResponsive, screenWidth } = useScreenSize();

  const isMobile = screenWidth < 450;
  const home = !isResponsive
    ? homejson
    : homejson?.sort(
        (a, b) => +a?.['mobile-position'] - +b?.['mobile-position']
      );
  console.log('home', home);
  const { t } = useTranslation('home');
  const { locale: language } = UseLocale();

  return (
    <>
      <Seo
        title={metadata.home[language]?.title}
        description={metadata.home[language]?.desc}
      >
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(homeSchema()),
          }}
        />
      </Seo>

      <section className='*:mb-4'>
        {home?.length
          ? home?.map((section, i) =>
              section?.active ? (
                <div
                  className={`${section.key !== 'first-section' ? (['today-offers', 'products-list'].includes(section.key) ? '' : 'mx-auto w-full max-sm:mx-0') : 'mx-auto w-full'} *:mb-6`}
                  key={`render-home-page-${i}`}
                >
                  {renderHomeSection(section.key, section, t, isMobile)}
                </div>
              ) : null
            )
          : null}

        {/* my recommendation */}
      </section>
    </>
  );
};

export default HomePage;

export async function getServerSideProps({ locale, req, res }) {
  // get handshake from cookies or generate new handshake
  const handshake = await fetchHandshake({ req, res, locale });
  console.log('!!!!!!!!!!!!!!!!!!', locale);
  // get home page structure json
  const { data, error } = await getHomeJson({
    handshake,
    store: locale?.slice(-2),
  });
  if (error) {
    return {
      props: {},
    };
  }

  const shipping_free_limit = data?.data?.[0]?.shipping_free_limit;
  shipping_free_limit &&
    setCookie('shipping_free_limit', shipping_free_limit, { req, res });

  const shipping_free_rule_id = data?.data?.[0]?.shipping_free_rule_id;
  shipping_free_rule_id &&
    setCookie('shipping_free_rule_id', shipping_free_rule_id, { req, res });

  const homeJson = Array.isArray(data?.data?.[0]?.data)
    ? data?.data?.[0]?.data
    : [];

  const mainBanners =
    homeJson?.find((item) => item.key === 'main-banners') || {};
  const sideBanners =
    homeJson?.find((item) => item.key === 'side-banners') || {};

  const mainSlider = homeJson?.find((item) => item.key === 'main-slider') || {};

  const firstSection =
    mainBanners?.active && sideBanners?.active && mainSlider?.active
      ? {
          position: 1,
          key: 'first-section',
          active: true,
          main_banners: mainBanners,
          side_banners: sideBanners,
          main_slider: mainSlider,
        }
      : {};

  const static_parts = [...homeJson, firstSection]?.filter(
    (item) =>
      !['main-banners', 'side-banners', 'main-slider'].includes(item.key)
  );

  const home = [...(Array.isArray(static_parts) ? static_parts : [])]
    .sort((a, b) => +a.position - +b.position)
    ?.filter((tab) => tab.active);

  return {
    props: {
      home,
      ...(await serverSideTranslations(locale, ['common', 'home'])),
    },
  };
}
