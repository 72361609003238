import React from "react";

const LazyLoading = ({ parentClassName }) => {
  return (
    <div className={`flex items-center justify-center ${parentClassName}`}>
      <span className="size-4 sm:size-8  animate-spin rounded-full border-r border-t border-black" />
    </div>
  );
};

export default LazyLoading;
